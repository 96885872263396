export default defineI18nConfig(() => ({
  datetimeFormats: {
    en: {
      short: {
        year: 'numeric', month: 'short', day: 'numeric',
      },
      long: {
        year: 'numeric', month: 'short', day: 'numeric',
        weekday: 'short', hour: 'numeric', minute: 'numeric',
      },
    },
    cs: {
      short: {
        year: 'numeric', month: 'short', day: 'numeric',
      },
      long: {
        year: 'numeric', month: 'short', day: 'numeric',
        weekday: 'short', hour: 'numeric', minute: 'numeric',
      },
    },
  },
  numberFormats: {
    en: {
      currency: {
        style: 'currency', currency: 'EUR', notation: 'standard',
      },
      decimal: {
        style: 'decimal', maximumFractionDigits: 2,
      },
      percent: {
        style: 'percent', useGrouping: false,
      },
    },
    cs: {
      currency: {
        style: 'currency', currency: 'CZK', notation: 'standard',
      },
      decimal: {
        style: 'decimal', maximumFractionDigits: 2, notation: 'standard',
      },
      percent: {
        style: 'percent', useGrouping: false,
      }
    },
  },
}))
